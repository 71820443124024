import React from 'react';
import PropTypes from 'prop-types';
import { InputAdornment } from "@material-ui/core";
import TextField from '@material-ui/core/TextField'

const PercentageField = ({ onChange, value, label }) => {
    return (
        <TextField
            style={{
              margin: "10px 0",
            }}
            label={label}
            value={value}
            onChange={onChange}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>
              }}
          />
    );
};

PercentageField.defaultProps = {
    onChange: () => "",
    value: "",
    label: ""
};

PercentageField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default PercentageField;