import React from 'react';
import PropTypes from 'prop-types';
import CurrencyFormat from "../Formatters/CurrencyFormat";
import TextField from '@material-ui/core/TextField'

const CurrencyField = ({ onChange, value, label }) => {
    return (
        <TextField
            style={{
              margin: "10px 0",
            }}
            label={label}
            value={value}
            onChange={onChange}
            InputProps={{
              inputComponent: CurrencyFormat,
            }}
          />
    );
};

CurrencyField.defaultProps = {
    onChange: () => "",
    value: "",
    label: ""
};

CurrencyField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
};

export default CurrencyField;