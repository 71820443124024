import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";

const ToolTitle = ({ text }) => {
    return (
        <Typography variant="h6">{text}</Typography>
    );
};

ToolTitle.defaultProps = {
    text: ""
};

ToolTitle.propTypes = {
    text: PropTypes.string.isRequired
};

export default ToolTitle;