import React from "react";
import { ExpansionPanel, ExpansionPanelDetails, ExpansionPanelSummary } from "@material-ui/core";

import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core/styles';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const styles = theme => ({
  root: {
    width: 'auto',
    marginTop: theme.spacing.unit * 2,
    margin: '0',
    overflowX: 'auto',
    padding: 4,
  },
  table: {
    minWidth: '300',
    padding: '0',
  },

});

function AmortizationSchedule(props) {
  //const { classes } = props;
  return (
    <ExpansionPanel className="Expander" >
    <ExpansionPanelSummary expandIcon={<ExpandMoreIcon />}>
      <Typography>Amortization</Typography>
    </ExpansionPanelSummary>
    <ExpansionPanelDetails className="Table">
      <Table className="table">
        <TableHead>
          <TableRow>
            <TableCell>Month</TableCell>
            <TableCell align="right">Interest</TableCell>
            <TableCell align="right">Principal</TableCell>
            <TableCell align="right">Balance</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.rows.map(row => (
            <TableRow key={row.id}>
              <TableCell align="right">{row.month}</TableCell>
              <TableCell align="right">{row.interest}</TableCell>
              <TableCell align="right">{row.principal}</TableCell>
              <TableCell align="right">{row.balance}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </ExpansionPanelDetails>
  </ExpansionPanel>
      
  );
}

export default withStyles(styles)(AmortizationSchedule) ;