
import Paper from '@material-ui/core/Paper';
import React from "react";
import "react-input-range/lib/css/index.css";
import CurrencyField from "./TextField/CurrencyField";
import ToolTitle from "./ToolTitle";
import LoanCalculator from "./LoanCalculator";

import "../styles/Calculator.css";
import { Checkbox } from '@material-ui/core';

class ReCalc extends React.Component {
  state = {
    price: 100000,
    principal: 100000,
    years: 30,
    rate: 4.0,
    paymentAmount: 0,
    amortizationData: [],
    showAmortization: false,
    addLoan: false,
  }

  handleChange = name => event => {
    console.log("setting %s to %s", name, event.target.checked)
    if(name === "addLoan") {
      this.setState({
        [name]: event.target.checked,
      })
    } else {
      this.setState({ 
        [name]: event.target.value,
      })
    }
  }

  handleLoanChange = name => event => {
    this.setState({ 
      [name]: event.target.value,
    })
  }

  render() {
    return (
      <div className="CentralContainer">
        <Paper className="App">
          <ToolTitle text="Real Estate Evaluator" />
            <CurrencyField
              label="purchase price"
              value={this.state.price.toString()}
              handleCheckboxChange={this.handleChange('price')} />
            <label>
              <Checkbox
                label="associated loan"
                key="associated loan"
                onChange={this.handleChange('addLoan')} />
              associated loan
            </label>
            <LoanCalculator data={this.state} onChange={this.handleLoanChange} />
        </Paper>
      </div>
    );
  }
}

  export default ReCalc;