import TextField from '@material-ui/core/TextField';
import React from "react";
import "react-input-range/lib/css/index.css";
import CurrencyField from "./TextField/CurrencyField";
import PercentageField from "./TextField/PercentageField";
import Decimal from "decimal.js";

class LoanCalculator extends React.Component {

  onFieldChange(event) {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    this.props.onChange(fieldName, fieldValue);
  }

  static calculatePayment(amount, rate, months) {
    var dr = Decimal.div(rate, 12);
    var payments = months;
    var numerator = Decimal.mul(dr, Decimal.pow(Decimal.add(1, dr), payments)); 
    var denom = Decimal.sub(Decimal.pow(Decimal.add(1, dr), payments), 1);
    var decValue = Decimal(amount)
    var amt;
    if(denom === 0) {
      amt = Decimal.div(decValue, payments);
    } else {
      amt = Decimal.mul(decValue, Decimal.div(numerator, denom));
    }
    return amt;
  }

  render() {
    return (
      <div className="widget">
          <CurrencyField
            label="amount borrowed"
            value={this.props.data.principal.toString()}
            onChange={this.props.onChange('principal')} />
          <PercentageField
            label="interest rate"
            value={this.props.data.rate.toString()}
            onChange={this.props.onChange('rate')}
          />
          <TextField
            style={{
              margin: "10px 0",
            }}
            label="loan term (years)"
            value={this.props.data.years}
            onChange={this.props.onChange('years')}
          />
      </div>
    );
  }
}


export default LoanCalculator;