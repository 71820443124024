import React, { Component } from 'react'
import Calculator from './components/Calculator';
import ReCalc from './components/ReCalc';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import { BrowserRouter, Route, Switch, NavLink } from "react-router-dom"

class App extends Component {
  
  render() {
    var loanItem = <NavLink className="menuitem" activeClassName="active" to="/">Loan Calculator</NavLink>;
    //var reItem = <NavLink className="menuitem" activeClassName="active" to="/recalc">Real Estate Evaluator</NavLink>;

    return (
      <div>
        <BrowserRouter>
          <AppBar position="static" color="default">
            <Toolbar>
                {loanItem}
            </Toolbar>
          </AppBar>

          <Switch>
            <Route exact path="/" component={Calculator} />
            <Route path="/recalc" component={ReCalc} />
          </Switch>
        </BrowserRouter>
      </div>
    )
  }

}
export default App