
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Decimal from "decimal.js";
import React from "react";
import "react-input-range/lib/css/index.css";

import "../styles/Calculator.css";
import LoanCalculator from "./LoanCalculator";
import AmortizationSchedule from "./AmortizationSchedule";
import ToolTitle from "./ToolTitle";

class Calculator extends React.Component {
    state = {
      principal: 100000,
      years: 30,
      rate: 4.0,
      decRate: Decimal(4.0 / 100),
      paymentAmount: 0,
      amortizationData: [],
      showAmortization: false,
    }
  
    componentDidMount() {
      this.calculatePaymentAmount();
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevState !== this.state) {
        if(prevState.principal !== this.state.principal ||
          prevState.years !== this.state.years ||
          prevState.rate !== this.state.rate ||
          prevState.decRate !== this.state.decRate) {
            if(this.state.principal < 100) {
              this.setState({paymentAmount: 0});
              return;
            }
          this.calculatePaymentAmount();
        }
        if(prevState.paymentAmount !== this.state.paymentAmount) {
          this.createAmortization();
        }
      }
    }

    calculatePaymentAmount = () => {
      if(this.state.principal === "" || this.state.principal === 0) {
        return;
      }

      var dr;
      if(this.state.rate === "" || isNaN(this.state.rate)) {
        console.log("setting to 0");
        dr = Decimal(0);
        return;
      }
      dr = Decimal(this.state.rate);
      dr = Decimal.div(dr, 100);
      if(this.state.decRate.toFixed(4) !== dr.toFixed(4)) {
        this.setState({decRate: dr});
      }
      
      var amt = LoanCalculator.calculatePayment(this.state.principal, this.state.decRate, this.state.years * 12);
      var newAmt = amt.toFixed(2);
      if(this.state.paymentAmount !== newAmt) {
        this.setState({paymentAmount: newAmt});
      }
    };

    createData = (id, month, interest, principal, balance) => {
      return { id, month, interest, principal, balance };
    }

    createAmortization = () => {
      if(this.state.paymentAmount === 0)
        return; 

      var rowdata = [];
      var principal = Decimal(this.state.principal * 100);
      var monthlyRate = Decimal.div(this.state.decRate, 12);
      var payAmount = Decimal(this.state.paymentAmount * 100);
      var baseDate = new Date();

      while (principal > 0 && rowdata.length < this.state.years * 12) {
        var interestAmt = Decimal.mul(principal, monthlyRate);
        var principalAmt = Decimal.max(0, Decimal.min(principal, Decimal.sub(payAmount, interestAmt)), 0);
        principal = Decimal.sub(principal, principalAmt);
        var index = rowdata.length;

        var rowDate = new Date();
        rowDate.setMonth(baseDate.getMonth() + index % 12, 1);
        rowDate.setFullYear(baseDate.getFullYear() + ((baseDate.getMonth() + index) / 12));
        var monthTxt = rowDate.getMonth() + 1 + "/" + rowDate.getFullYear();
        rowdata.push( this.createData(index, monthTxt, 
          Decimal.div(Decimal.round(interestAmt), 100).toFixed(2).toString(), 
          Decimal.div(principalAmt, 100).toFixed(2).toString(), 
          Decimal.div(principal, 100).toFixed(2)));
      }
      
      if(this.state.amortizationData !== rowdata) {
        this.setState({
          amortizationData: rowdata,
        })
      }
    };

    handleChange = name => event => {
      //console.log("setting %s to %s", name, event.target.value);
      var value = String(event.target.value);
      value = value.replace(/[^\d.-]/g, '');
      if(value.startsWith('.')) {
        value = '0' + value;
      }
      
      this.setState({ 
        [name]: value,
      })
    }

    render() {
      let { paymentAmount} = this.state;
  
      return (
        <div className="CentralContainer">
          <Paper className="App">
            <ToolTitle text="Loan Payment Calculator" />
            <LoanCalculator data={this.state} onChange={this.handleChange} />
            <Typography variant="h4"> ${paymentAmount}/mo </Typography>
            <AmortizationSchedule rows={this.state.amortizationData} />
          </Paper>
        </div>
      );
    }
  }

  export default Calculator;